<template>
  <ion-page>
    <!-- <div class="background-main-view main-view"> -->
    <ion-header class="ion-no-border">
      <ion-toolbar color="transparent">
        <div slot="start">
          <ion-button
            v-if="selectedView === '1'"
            fill="clear"
            color="transparent"
            size="large"
            class="return-back-button"
            @click="$router.push('/landlord-properties')"
          >
            <img
              src="../assets/images/arrow-go-back-line.svg"
            >
          </ion-button>
          <ion-button
            v-else
            fill="clear"
            color="transparent"
            size="large"
            class="return-back-button"
            @click="selectedView = '1'"
          >
            <img
              src="../assets/images/arrow-go-back-line.svg"
            >
          </ion-button>
        </div>
        <div slot="end">
          <ion-button
            fill="clear"
            color="transparent"
            size="large"
            class="return-back-button"
            @click="openBuildingMenu(buildingDetails)"
          >
            <i
              class="mdi mdi-tune text--white"
              style="font-size: 25px;"
            />
          </ion-button>
        </div>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <!-- Loading -->
      <transition
        name="fade"
        mode="out-in"
      >
        <div
          v-if="loading"
          key="1"
        >
          <ion-progress-bar
            type="indeterminate"
          />
          <div class="mt-20">
            <ion-text style="font-size: 20px">
              Loading....
            </ion-text>
          </div>
        </div>
        <!-- main page -->
        <div
          v-else
          key="2"
          class="ion-padding-start ion-padding-end"
        >
          <transition
            name="fade"
            mode="out-in"
          >
            <div
              v-if="selectedView === '1'"
              key="3"
            >
              <!-- Property name -->
              <ion-row
                style="margin-bottom: 5px;"
                class="ion-no-padding"
              >
                <ion-col class="ion-no-padding">
                  <ion-text
                    class="text--white text-18"
                  >
                    {{ buildingDetails.length ? buildingDetails[0].buildingName : 'None' }}
                  </ion-text>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col>
                  <div class="text--white text-14">
                    {{ buildingDetails.length ? buildingDetails[0].buildingAddress : 'None' }}, {{ buildingDetails.length ? buildingDetails[0].buildingPostCode : 'None' }},
                    {{ buildingDetails.length ? buildingDetails[0].buildingCity : 'None' }}
                  </div>
                </ion-col>
              </ion-row>
              <!-- Property address -->
              <div class="text-left mt-10">
                <div>
                  <!-- Segment buttons -->
                  <ion-segment
                    swipe-gesture="true"
                    style="max-width: 600px"
                    :value="selectedViewApartment"
                    @ionChange="selectedViewApartment = $event.target.value"
                  >
                    <ion-segment-button value="1">
                      <ion-label
                        class="text-16"
                      >
                        Overview
                      </ion-label>
                    </ion-segment-button>
                    <ion-segment-button value="2">
                      <ion-label
                        class="text-16"
                      >
                        Apartments
                      </ion-label>
                    </ion-segment-button>
                    <ion-segment-button
                      v-if="platform.includes('desktop')"
                      value="3"
                    >
                      <ion-label
                        class="text-16"
                      >
                        3D Model
                      </ion-label>
                    </ion-segment-button>
                    <ion-segment-button value="4">
                      <ion-label
                        class="text-16"
                      >
                        Events <ion-badge
                          class="text--dark"
                        >
                          {{ eventsInPropertyCount }}
                        </ion-badge>
                      </ion-label>
                    </ion-segment-button>
                  </ion-segment>
                </div>
                <!-- Overview -->
                <div
                  v-if="selectedViewApartment === '1'"
                  key="4"
                >
                  <div class="element-background">
                    <div>
                      <ion-row>
                        <ion-col
                          size-xs="12"
                          size-xl="6"
                        >
                          <div>
                            <ion-row>
                              <ion-col
                                class="text-right"
                                size-xs="12"
                                size-xl="6"
                              >
                                <ion-item
                                  color="transparent"
                                  lines="none"
                                  class="selector text--white"
                                >
                                  <ion-label>Time period</ion-label>
                                  <ion-select v-model="selectedTimePeriod">
                                    <ion-select-option
                                      v-for="period in timePeriodSelector"
                                      :key="period"
                                      :value="period.name"
                                    >
                                      {{ period.name }}
                                    </ion-select-option>
                                  </ion-select>
                                  <i
                                    slot="end"
                                    class="mdi mdi-chevron-down"
                                  />
                                </ion-item>
                              </ion-col>
                              <ion-col
                                size-xs="12"
                                size-xl="6"
                              >
                                <date-picker
                                  v-model="dataDateRangeSelectorWeek"
                                  is-range
                                  color="orange"
                                >
                                  <template #default="{ inputValue, inputEvents }">
                                    <div class="flex justify-center items-center">
                                      <input
                                        :value="inputValue.start"
                                        class="text--white no-outline"
                                        style="padding: 5px 10px 5px 10px; margin-top: 10px; width: 45%; height: 30px; margin-right: 5%;"
                                        v-on="inputEvents.start"
                                      >
                                      <i
                                        class="mdi mdi-chevron-right text--white"
                                        style="position: absolute; top: 20px; left: 45%;"
                                      />
                                      <input
                                        :value="inputValue.end"
                                        class="text--white no-outline"
                                        style="padding: 5px 10px 5px 10px; margin-top: 10px; width: 50%; height: 30px;"
                                        v-on="inputEvents.end"
                                      >
                                    </div>
                                  </template>
                                </date-picker>
                              </ion-col>
                            </ion-row>
                            <ion-row>
                              <ion-col
                                size-xl="3"
                                size-lg="3"
                                size-md="3"
                                size-sm="12"
                                size-xs="12"
                              >
                                <!-- <i
                                    class="mdi mdi-stairs text--white"
                                    style="margin-top: 15px; margin-right: 5px;"
                                  /> -->
                                <ion-item
                                  class="selector text--white"
                                  lines="none"
                                  color="transparent"
                                >
                                  <ion-label>Floor</ion-label>
                                  <ion-select v-model="selectedFlor">
                                    <ion-select-option value="All">
                                      All
                                    </ion-select-option>
                                    <ion-select-option
                                      v-for="floor in buildingDetails[0].buildingFloors"
                                      :key="floor"
                                      :value="floor"
                                    >
                                      {{ floor }}
                                    </ion-select-option>
                                  </ion-select>
                                  <i class="mdi mdi-chevron-down" />
                                </ion-item>
                              </ion-col>
                              <ion-col
                                size-xl="6"
                                size-lg="6"
                                size-md="6"
                                size-sm="12"
                                size-xs="12"
                                style="display: inline;"
                              >
                                <!-- <i
                                    class="mdi mdi-view-quilt text--white"
                                    style="margin-top: 15px; margin-right: 5px;"
                                  /> -->
                                <ion-item
                                  class="selector text--white"
                                  lines="none"
                                  color="transparent"
                                  style="min-width: 290px;"
                                >
                                  <ion-label>Apartment</ion-label>
                                  <ion-select v-model="selectedFlat">
                                    <ion-select-option value="All">
                                      All
                                    </ion-select-option>
                                    <ion-select-option
                                      v-for="property in selectorProperties"
                                      :key="property"
                                      :value="property.id"
                                    >
                                      {{ property.propertyName }}
                                    </ion-select-option>
                                  </ion-select>
                                  <i class="mdi mdi-chevron-down" />
                                </ion-item>
                              </ion-col>
                              <ion-col
                                class="text-right"
                              >
                                <ion-button
                                  class="display-data-button"
                                  style="margin-top: 10px;"
                                  :disabled="loadingCharts"
                                  :style="platform.includes('mobile') ? 'margin-left: 5px; max-width: 120px;' : ''"
                                  @click="getDataForCharts()"
                                >
                                  <ion-spinner
                                    v-if="loadingCharts"
                                    color="dark"
                                    style="max-width: 25px;"
                                    name="crescent"
                                  />
                                  <ion-label v-if="!loadingCharts">
                                    Show
                                  </ion-label>
                                </ion-button>
                              </ion-col>
                            </ion-row>
                          </div>
                        </ion-col>
                        <ion-col
                          size-xs="0"
                          size-sm="2"
                        />
                        <ion-col>
                          <ion-row>
                            <ion-col
                              size-xl="4"
                              class="text-left"
                            >
                              <ion-label class="text--grayish text--uppercase text-12 text--bold">
                                Floors
                              </ion-label>
                              <div class="text--white mt-5">
                                <i class="mdi mdi-office-building text--gray text-18 pr-5" />{{ buildingDetails.length ? buildingDetails[0].buildingFloors : 'None' }}
                              </div>
                              <div class="mt-10">
                                <ion-label class="text--grayish text--uppercase text-12 text--bold">
                                  Errors
                                </ion-label>
                                <div class="text--white mt-5">
                                  <i
                                    class="mdi mdi-alert-circle pr-5"
                                    :class="errors(buildingDetails.id) === 0 ? 'text--green' : 'text--danger'"
                                  />{{ errors(buildingDetails.id) }}
                                </div>
                              </div>
                            </ion-col>
                            <ion-col
                              size-xl="4"
                              class="text-left"
                            >
                              <ion-label class="text--grayish text--uppercase text-12 text--bold">
                                Aparments
                              </ion-label>
                              <div class="text--white mt-5">
                                <i class="mdi mdi-view-quilt text--gray text-18 pr-5" />{{ buildingDetails.length ? buildingDetails[0].buildingFlatsPerFloor * buildingDetails[0].buildingFloors : 'None' }}
                              </div>
                              <div class="mt-10">
                                <ion-label class="text--grayish text--uppercase text-12 text--bold">
                                  Alerts
                                </ion-label>
                                <div class="text--white mt-5">
                                  <i
                                    class="mdi mdi-alert pr-5"
                                    :class="alerts(buildingDetails.id) === 0 ? 'text--green' : 'text--danger'"
                                  />{{ alerts(buildingDetails.id) }}
                                </div>
                              </div>
                            </ion-col>
                            <ion-col
                              size-xl="4"
                              class="text-left"
                            >
                              <ion-label class="text--grayish text--uppercase text-12 text--bold">
                                Devices
                              </ion-label>
                              <div class="text--white mt-5">
                                <i class="mdi mdi-application text--gray text-18 pr-5" />{{ buildingDevices.length }}
                              </div>
                              <div class="mt-10">
                                <ion-label class="text--grayish text--uppercase text-12 text--bold">
                                  Warnings
                                </ion-label>
                                <div class="text--white mt-5">
                                  <i
                                    class="mdi mdi-comment-alert pr-5"
                                    :class="warnings(buildingDetails.id) === 0 ? 'text--green' : 'text--danger'"
                                  />{{ warnings(buildingDetails.id) }}
                                </div>
                              </div>
                            </ion-col>
                          </ion-row>
                        </ion-col>
                      </ion-row>
                    </div>
                    <ion-grid>
                      <ion-row
                        v-if="chartsViewAction === 'all' || chartsViewAction === 'floor'"
                        class="mt-10"
                      >
                        <!-- Temp out in --------------------- -->
                        <ion-col
                          size-xl="6"
                          size-lg="12"
                          size-md="12"
                          size-sm="12"
                          size-xs="12"
                        >
                          <div class="chart-col-bg">
                            <div
                              style="position: absolute; top: 10px; right: 10px; font-size: 20px; z-index: 1000;"
                            >
                              <tippy>
                                <i class="mdi mdi-help-circle text--gray" />
                                <template
                                  #content
                                >
                                  <div
                                    style="font-size: 14px; padding: 5px 10px 5px 10px;"
                                  >
                                    <span class="text--bold pb-5">Indoor vs outdoor</span>
                                    <div class="mt-5">
                                      Detta diagram visar hur temperaturen i fastigheten har varierat som en funktion av den utomhustemperatur som förekommit under den valda perioden.
                                    </div>
                                  </div>
                                </template>
                              </tippy>
                            </div>
                            <apexchart
                              :height="300"
                              type="line"
                              :options="indoorVsOutdoor"
                              :series="indoorVsOutdoorSerie"
                            />
                          </div>
                        </ion-col>
                        <!-- percentual temp spread --------------------- -->
                        <ion-col
                          v-if="chartsViewAction === 'all'"
                          size-xl="6"
                          size-lg="12"
                          size-md="12"
                          size-sm="12"
                          size-xs="12"
                        >
                          <div class="chart-col-bg">
                            <div
                              style="position: absolute; top: 10px; right: 10px; font-size: 20px; z-index: 1000;"
                            >
                              <tippy>
                                <i class="mdi mdi-help-circle text--gray" />
                                <template
                                  #content
                                >
                                  <div
                                    style="font-size: 14px; padding: 5px 10px 5px 10px;"
                                  >
                                    <span class="text--bold pb-5">Temperaturspridning</span>
                                    <div class="mt-5">
                                      Diagrammet visualiserar över- och undertemperatur, i huset som helhet, i förhållande till måltemperaturen för att kunna uppskatta både energibehov samt boendekomfort.
                                      <ul>
                                        <li>Övertemperatur, staplar som visar fördelningen av enskilda temperaturobservationer över måltemperaturen. Kan användas för att uppskatta den besparingspotential som finns tillgänglig genom att sänka övertemperaturer ner till måltemperaturen.</li><br>
                                        <li>Undertemperatur, staplar som visar fördelningen av enskilda temperaturobservationer under måltemperaturen. Kan användas för att estimera den kostnad som skulle uppkomma för att höja undertemperaturer upp till måltemperaturen.</li>
                                      </ul>
                                    </div>
                                  </div>
                                </template>
                              </tippy>
                            </div>
                            <apexchart
                              :height="250"
                              :options="targetTemp"
                              :series="targetTempSeries"
                            />
                            <ion-row style="margin-top: -10px;">
                              <ion-col>
                                <div class="text--white ml-30">
                                  Average Temperature<br>
                                  <span
                                    v-if="averageTemp.average"
                                    class="text--primary"
                                    style="font-size: 20px;"
                                  >
                                    {{ averageTemp.average }}°C
                                  </span>
                                  <ion-spinner
                                    v-else
                                    style="max-width: 20px; margin-top: -5px;"
                                    name="crescent"
                                  />
                                </div>
                              </ion-col>
                              <ion-col>
                                <span class="text--white">Target Temperature <br></span><span
                                  class="text--primary"
                                  style="font-size: 20px;"
                                > {{ buildingDetails.length ? buildingDetails[0].targetTemperature : 0 }}°C</span>
                              </ion-col>
                            </ion-row>
                          </div>
                        </ion-col>
                        <!-- Temp per floor -->
                        <ion-col
                          v-if="chartsViewAction === 'all'"
                          size-xl="6"
                          size-lg="12"
                          size-md="12"
                          size-sm="12"
                          size-xs="12"
                        >
                          <div class="chart-col-bg">
                            <div
                              style="position: absolute; top: 10px; right: 10px; font-size: 20px; z-index: 1000;"
                            >
                              <tippy>
                                <i class="mdi mdi-help-circle text--gray" />
                                <template
                                  #content
                                >
                                  <div
                                    style="font-size: 14px; padding: 5px 10px 5px 10px;"
                                  >
                                    <span class="text--bold pb-5">Genomsnittlig temperatur per våningsplan</span>
                                    <div class="mt-5">
                                      Diagrammet visar medeltemperaturerna för samtliga givare monterade på samma våningsplan under den valda perioden. Här visas även i förekommande fall värden från närmaste utomhustemperaturgivare.
                                    </div>
                                  </div>
                                </template>
                              </tippy>
                            </div>
                            <apexchart
                              :height="300"
                              type="line"
                              :options="tempPerFloor"
                              :series="tempPerFloorSeries"
                            />
                          </div>
                        </ion-col>
                        <!-- temp per apartment -->
                        <ion-col
                          v-if="chartsViewAction === 'all'"
                          size-xl="6"
                          size-lg="12"
                          size-md="12"
                          size-sm="12"
                          size-xs="12"
                        >
                          <div class="chart-col-bg">
                            <div
                              style="position: absolute; top: 10px; right: 10px; font-size: 20px; z-index: 1000;"
                            >
                              <tippy>
                                <i class="mdi mdi-help-circle text--gray" />
                                <template
                                  #content
                                >
                                  <div
                                    style="font-size: 14px; padding: 5px 10px 5px 10px;"
                                  >
                                    <span class="text--bold pb-5">Temparature per apartment</span>
                                    <div class="mt-5">
                                      TBA
                                    </div>
                                  </div>
                                </template>
                              </tippy>
                            </div>
                            <apexchart
                              :height="300"
                              :options="tempPerApartment"
                              :series="tempPerApartmentSeries"
                            />
                          </div>
                        </ion-col>
                        <!-- temp spread if floor selected -->
                        <ion-col
                          v-if="chartsViewAction === 'floor'"
                          size-xl="6"
                          size-lg="12"
                          size-md="12"
                          size-sm="12"
                          size-xs="12"
                        >
                          <div class="chart-col-bg text--white">
                            <ion-row class="rounded-corners-top pl-10 pt-5">
                              <ion-col
                                class="text-center text--bold"
                                size="12"
                              >
                                Overview
                              </ion-col>
                            </ion-row>
                            <ion-row class="average-temperature-header pl-10 pb-5 pt-5 text-14">
                              <ion-col size="4" />
                              <ion-col size="2">
                                Average
                              </ion-col>
                              <ion-col size="2">
                                Min
                              </ion-col>
                              <ion-col size="2">
                                Max
                              </ion-col>
                            </ion-row>
                            <div
                              v-for="i in tempPerApartmentBelowAverage"
                              :key="i"
                            >
                              <ion-row
                                class="pl-10 pt-5 pb-5 text-14"
                              >
                                <ion-col size="4">
                                  {{ i.x }}
                                </ion-col>
                                <ion-col size="2">
                                  {{ i.y }} °C
                                </ion-col>
                                <ion-col size="2">
                                  {{ i.min }} °C
                                </ion-col>
                                <ion-col size="2">
                                  {{ i.max }} °C
                                </ion-col>
                              </ion-row>
                            </div>
                            <ion-row class="average-temperature pl-10 pt-5 pb-5 text-14">
                              <ion-col size="4">
                                Average temperature:
                              </ion-col>
                              <ion-col
                                style="color: #FFA500;"
                                size="2"
                              >
                                {{ averageTemp.average }} °C
                              </ion-col>
                              <ion-col
                                style="color: #87CEFA;"
                                size="2"
                              >
                                {{ tempPerApartmentMinAverage.minAverage.toFixed(1) }} °C
                              </ion-col>
                              <ion-col
                                style="color: #FF6347;"
                                size="2"
                              >
                                {{ tempPerApartmentMinAverage.maxAverage.toFixed(1) }} °C
                              </ion-col>
                            </ion-row>
                            <div
                              v-for="i in tempPerApartmentAboveAverage"
                              :key="i"
                            >
                              <ion-row class="pl-10 pt-5 pb-5 text-14">
                                <ion-col size="4">
                                  {{ i.x }}
                                </ion-col>
                                <ion-col size="2">
                                  {{ i.y }} °C
                                </ion-col>
                                <ion-col size="2">
                                  {{ i.min }} °C
                                </ion-col>
                                <ion-col size="2">
                                  {{ i.max }} °C
                                </ion-col>
                              </ion-row>
                            </div>
                          </div>
                        </ion-col>
                      </ion-row>
                      <!-- if flat is selected -->
                      <ion-row
                        v-if="chartsViewAction === 'flat'"
                        class="mt-10"
                      >
                        <ion-col
                          size-xl="12"
                          size-lg="12"
                          size-md="12"
                          size-sm="12"
                          size-xs="12"
                        >
                          <div class="chart-col-bg">
                            <div
                              style="position: absolute; top: 10px; right: 10px; font-size: 20px; z-index: 1000;"
                            >
                              <tippy>
                                <i class="mdi mdi-help-circle text--gray" />
                                <template
                                  #content
                                >
                                  <div
                                    style="font-size: 14px; padding: 5px 10px 5px 10px;"
                                  >
                                    <span class="text--bold pb-5">Indoor vs outdoor</span>
                                    <div class="mt-5">
                                      Detta diagram visar hur temperaturen i fastigheten har varierat som en funktion av den utomhustemperatur som förekommit under den valda perioden.
                                    </div>
                                  </div>
                                </template>
                              </tippy>
                            </div>
                            <apexchart
                              :height="300"
                              type="line"
                              :options="indoorVsOutdoor"
                              :series="indoorVsOutdoorSerie"
                            />
                          </div>
                        </ion-col>
                      </ion-row>
                      <ion-row
                        v-if="chartsViewAction === 'flat'"
                        class="mt-10"
                      >
                        <ion-col
                          size-xl="12"
                          size-lg="12"
                          size-md="12"
                          size-sm="12"
                          size-xs="12"
                        >
                          <div class="chart-col-bg">
                            <div
                              style="position: absolute; top: 10px; right: 10px; font-size: 20px; z-index: 1000;"
                            >
                              <tippy>
                                <i class="mdi mdi-help-circle text--gray" />
                                <template
                                  #content
                                >
                                  <div
                                    style="font-size: 14px; padding: 5px 10px 5px 10px;"
                                  >
                                    <span class="text--bold pb-5">Heatmap</span>
                                    <div class="mt-5">
                                      TBA
                                    </div>
                                  </div>
                                </template>
                              </tippy>
                            </div>
                            <apexchart
                              :height="600"
                              :options="heatMapPerApartment"
                              :series="heatMapPerApartmentSeries"
                            />
                          </div>
                        </ion-col>
                      </ion-row>
                    </ion-grid>
                  </div>
                  <div
                    class="spacer"
                  />
                </div>
                <!-- Apartments -->
                <div
                  v-else-if="selectedViewApartment === '2'"
                  key="5"
                >
                  <div class="element-background">
                    <ion-label class="text--white text--uppercase text-14 text--bold">
                      Apartments
                    </ion-label>
                    <ion-row>
                      <ion-col
                        size-xl="8"
                        size-md="12"
                        size-xs="12"
                      >
                        <div class="search-bar-wrapper">
                          <ion-searchbar
                            v-model="search"
                            class="search-bar rounded-corners"
                          />
                        </div>
                      </ion-col>
                      <ion-col
                        size-xl="4"
                        size-lg="4"
                      >
                        <ion-item
                          class="h-45 rounded-corners text--white"
                          lines="none"
                        >
                          <ion-label>
                            <span class="text--white">Select filter by type</span>
                          </ion-label>
                          <ion-select
                            v-model="selectedFilterPlaceType"
                            class="text--white"
                            placeholder="Select One"
                          >
                            <ion-select-option
                              v-for="{name, value} in typeSelector"
                              :key="name"
                              :value="value"
                            >
                              {{ name }}
                            </ion-select-option>
                          </ion-select>
                        </ion-item>
                      </ion-col>
                    </ion-row>
                    <ion-list class="ion-no-padding">
                      <ion-item
                        v-if="!filterProperties.length"
                        color="transparent"
                        lines="none"
                        button
                        @click="selectedView = '2'"
                      >
                        <div
                          style="margin: 30px auto;"
                          class="text--white"
                        >
                          Nothing found in this property.... Start by adding new appartments <i class="mdi mdi-plus-circle-outline text-22 text--primary" />
                        </div>
                      </ion-item>
                      <ion-item
                        v-for="{ id, propertyGateway, propertyName, fire, waterLeak, propertyType, residentName, gatewayActive } in filterProperties"
                        v-else
                        :key="id"
                        detail="false"
                        lines="none"
                        button
                        class="flat rounded-corners"
                        @click="$router.push(`/detail/${id}`)"
                      >
                        <i class="mdi mdi-view-quilt text--primary apartment-icon" />
                        <ion-grid>
                          <ion-row class="line">
                            <ion-col
                              size-xl="2"
                              size-lg="3"
                              size-xs="6"
                            >
                              <ion-text class="text--white text-18 pl-10">
                                {{ propertyName }}
                              </ion-text>
                            </ion-col>
                            <ion-col
                              size-xl="3"
                              size-lg="3"
                              size-xs="6"
                            >
                              <ion-text
                                class="text--white text-14"
                                style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis;"
                              >
                                <i class="mdi mdi-account text--primary pr-5" />{{ residentName }}
                              </ion-text>
                            </ion-col>
                          </ion-row>
                          <ion-row>
                            <ion-col
                              size-xl="2"
                              size-lg="3"
                              size-xs="6"
                            >
                              <ion-text class="text--gray text-14">
                                <i class="mdi mdi-format-list-bulleted-type text--gray pr-5 pl-10" />{{ propertyType }}
                              </ion-text>
                            </ion-col>
                            <ion-col
                              size-xl="3"
                              size-lg="3"
                              size-xs="6"
                            >
                              <ion-text>
                                <tippy v-if="propertyGateway && gatewayActive">
                                  <i
                                    class="mdi mdi-alert text-18"
                                    :class="errorsInProperty(id) === 0 ? 'text--green' : 'text--danger'"
                                  />
                                  <template
                                    #content
                                  >
                                    <div
                                      style="font-size: 14px; padding: 5px 10px 5px 10px;"
                                    >
                                      <span class="text--bold pb-5"><i class="mdi mdi-alert pr-5" />Errors</span>
                                      <div class="mt-5">
                                        There {{ errorsInProperty(id) > 1 ? 'are' : 'is' }} {{ errorsInProperty(id) }} errors in this apartment
                                      </div>
                                    </div>
                                  </template>
                                </tippy>
                                <tippy v-if="propertyGateway && fireSensorExists(id) && gatewayActive">
                                  <i
                                    class="mdi mdi-fire ml-5 text-18"
                                    :class="[fire ? 'text--danger' : 'text--green', gatewayActive ? '' : 'text--danger']"
                                  />
                                  <template
                                    #content
                                  >
                                    <div
                                      style="font-size: 14px; padding: 5px 10px 5px 10px;"
                                    >
                                      <span class="text--bold pb-5"><i class="mdi mdi-fire pr-5" />Smoke Sensor</span>
                                      <div class="mt-5">
                                        <span v-if="fire === true">
                                          Smoke detected in this apartment!!
                                        </span>
                                        <span v-else>
                                          All seems to be fine at this moment
                                        </span>
                                      </div>
                                    </div>
                                  </template>
                                </tippy>
                                <tippy v-if="propertyGateway && waterLeakExists(id) && gatewayActive">
                                  <i
                                    class="mdi mdi-water ml-5 text-18"
                                    :class="[waterLeak ? 'text--danger' : 'text--green', gatewayActive ? '' : 'text--danger' ]"
                                  />
                                  <template
                                    #content
                                  >
                                    <div
                                      style="font-size: 14px; padding: 5px 10px 5px 10px;"
                                    >
                                      <span class="text--bold pb-5"><i class="mdi mdi-water pr-5" />Water Leak Sensor</span>
                                      <div class="mt-5">
                                        <span v-if="waterLeak === true">
                                          Water leak in this apartment!!
                                        </span>
                                        <span v-else>
                                          All seems to be fine at this moment
                                        </span>
                                      </div>
                                    </div>
                                  </template>
                                </tippy>
                                <tippy v-if="propertyGateway && gatewayActive">
                                  <i
                                    class="mdi mdi-battery-alert ml-5 text-18"
                                    :class="[battery(id) === 0 || battery(id) === null ? 'text--green' : 'text--danger', gatewayActive ? '' : 'text--danger']"
                                  />
                                  <template
                                    #content
                                  >
                                    <div
                                      style="font-size: 14px; padding: 5px 10px 5px 10px;"
                                    >
                                      <span class="text--bold pb-5"><i class="mdi mdi-battery pr-5" />Bateries in sensors</span>
                                      <div class="mt-5">
                                        <span v-if="battery(id) === 0">
                                          All batteries seems to be fine at this moment
                                        </span>
                                        <span v-else>
                                          Some bateries may need replacing
                                        </span>
                                      </div>
                                    </div>
                                  </template>
                                </tippy>
                                <tippy v-if="propertyGateway && gatewayActive">
                                  <i
                                    class="mdi mdi-checkbox-blank-circle-outline ml-5 text-18"
                                    :class="[propertyGateway ? 'ml-5' : 'text--gray', gatewayActive ? 'text--green' : 'text--danger' ]"
                                  />
                                  <template
                                    #content
                                  >
                                    <div
                                      style="font-size: 14px; padding: 5px 10px 5px 10px;"
                                    >
                                      <span class="text--bold pb-5"><i class="mdi mdi-checkbox-blank-circle-outline pr-5" />Gateway Status</span>
                                      <div class="mt-5">
                                        Gateway is connected and healthy
                                      </div>
                                    </div>
                                  </template>
                                </tippy>
                                <tippy v-if="!propertyGateway">
                                  <i
                                    class="mdi mdi-checkbox-blank-circle-outline ml-5 text-18 text--gray"
                                  />
                                  <span class="text-14 text--gray ml-5">Gateway N/A</span>
                                  <template
                                    #content
                                  >
                                    <div
                                      style="font-size: 14px; padding: 5px 10px 5px 10px;"
                                    >
                                      <span class="text--bold pb-5"><i class="mdi mdi-checkbox-blank-circle-outline pr-5" />Gateway Status</span>
                                      <div class="mt-5">
                                        <span class="text--danger">Gateway has not been installed in this apartment</span>
                                      </div>
                                    </div>
                                  </template>
                                </tippy>
                                <tippy v-if="propertyGateway && !gatewayActive">
                                  <i
                                    class="mdi mdi-checkbox-blank-circle-outline ml-5 text-18"
                                    style="color: #f58c8c;"
                                  />
                                  <span
                                    class="text-14 ml-5"
                                    style="color: #f58c8c;"
                                  >Offline</span>
                                  <template
                                    #content
                                  >
                                    <div
                                      style="font-size: 14px; padding: 5px 10px 5px 10px;"
                                    >
                                      <span class="text--bold pb-5"><i class="mdi mdi-checkbox-blank-circle-outline pr-5" />Gateway Status</span>
                                      <div class="mt-5">
                                        <span class="text--danger">Gateway seems to be offline</span>
                                      </div>
                                    </div>
                                  </template>
                                </tippy>
                              <!-- <span v-tooltip="{ text: gatewayActive ? `Gateway connected` : 'Gateway disconnected', theme: { placement: 'right' }}">
                                  <i
                                    class="mdi text-18 mdi-checkbox-blank-circle-outline"
                                    :class="[propertyGateway ? 'ml-5' : 'text--gray', gatewayActive ? 'text--green' : 'text--danger' ]"
                                  />
                                  <span class="text-14 text--gray ml-5">{{ propertyGateway ? '' : 'Gateway N/A' }}</span>
                                </span> -->
                              </ion-text>
                            </ion-col>
                          </ion-row>
                        </ion-grid>
                        <i class="mdi mdi-chevron-right text--white text-22" />
                      </ion-item>
                    </ion-list>
                    <ion-fab
                      slot="fixed"
                      vertical="bottom"
                      horizontal="end"
                      @click="selectedView = '2'"
                    >
                      <ion-fab-button>
                        <ion-icon
                          color="dark"
                          :icon="addOutline"
                        />
                      </ion-fab-button>
                    </ion-fab>
                  </div>
                  <div
                    class="spacer"
                  />
                </div>
                <!-- 3d Model -->
                <div
                  v-else-if="selectedViewApartment === '3'"
                  key="6"
                >
                  <ion-label class="text--white text--uppercase text-14 text--bold">
                    3D Model
                  </ion-label>
                </div>
                <!-- Events -->
                <div
                  v-else-if="selectedViewApartment === '4'"
                  key="7"
                >
                  <div class="element-background">
                    <div class="text--white text--uppercase text-14 text--bold mb-5">
                      Current Events Log
                    </div>
                    <div
                      class="slector-col-bg"
                      style="margin-left: 10px;"
                    >
                      <ion-list>
                        <ion-label class="text--white text--uppercase text-12 text--bold">
                          <i
                            class="mdi mdi-alert-circle text--primary"
                            style="font-size: 20px; position: relative; top: 2px;"
                          />
                          Errors
                        </ion-label>
                        <div v-if="errorsInProperties.length">
                          <ion-item
                            v-for="error in errorsInProperties"
                            :key="error"
                            class="text--white flat rounded-corners"
                            lines="none"
                            button
                            @click="
                              error.gatewayActive === false ? $router.push(`/detail/${error.id}`) : $router.push(`/detail/${error.propertyId}`)"
                          >
                            <i
                              v-if="error.gatewayActive === false"
                              style="font-size: 35px; position: relative; top: 0px;"
                              class="mdi mdi-sync-off text--danger apartment-icon mr-10"
                            />
                            <div
                              v-if="error.gatewayActive === false"
                              class="text--white"
                              style="padding-left: 8px;"
                            >
                              <span class="text--white">{{ error.propertyName }} </span><span class="text--primary"> is Offline (Gateway is offline) </span> since {{ new Date(Number(error.disconnectedAt)).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}) }} @ {{ new Date(Number(error.disconnectedAt)).toLocaleDateString('sv-SV') }}
                            </div>
                            <div
                              v-else
                              class="text--white"
                            >
                              <!-- <span class="text--primary text--uppercase">{{ deviceNameMapper(error.typeI) }} is {{ error.active ? 'Connected' : 'Disconnected' }}</span>
                              <span class="text--white"> IN {{ propertyNameMapper(error.propertyId) }} </span> -->
                              <div style="display: inline-block;">
                                <i
                                  class="mdi mdi-signal-off text--danger"
                                  style="font-size: 35px; position: relative; top: -8px;"
                                />
                              </div>
                              <div style="display: inline-block; padding-left: 15px; margin-top: 5px;">
                                <div>
                                  <i
                                    class="mdi mdi-view-quilt text--primary"
                                    style="font-size: 25px; position: relative; bottom: -3px; margin-right: 5px;"
                                  />
                                  <span class="text--white">{{ propertyNameMapper(error.propertyId) }}</span>
                                  <span class="text--primary pl-10">{{ deviceNameMapper(error.typeI) }}</span> is {{ error.active ? 'Connected' : 'Disconnected' }}
                                </div>
                                <div
                                  class="text--white mt-5 text-14"
                                >
                                  <!-- <span class="text--primary">Detected</span> {{ new Date(Number(error.startTime)).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}) }} @ {{ new Date(Number(error.startTime)).toLocaleDateString('sv-SV') }} <span class="text--primary">Duration</span> {{ Math.round((Number(error.endTime) - Number(error.startTime)) / 1000 / 60) }} Minutes -->
                                  <span class="text--primary">Disconnected </span>{{ getDisconnectionDetail(error).startTime ? new Date(Number(getDisconnectionDetail(error).startTime)).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}) : 'Something has failed try refresh the page' }}
                                  @ {{ getDisconnectionDetail(error).startTime ? new Date(Number(getDisconnectionDetail(error).startTime)).toLocaleDateString('sv-SV') : '' }}
                                  <span class="text--primary">Duration </span> {{ Math.round(((Date.now() - Number(getDisconnectionDetail(error).startTime)) / 1000) / 60) }} Minutes
                                </div>
                              </div>
                            </div>
                          </ion-item>
                        </div>
                        <div
                          v-else
                          class="mt-8 text--white"
                        >
                          <i
                            class="mdi mdi-check text--green"
                            style="font-size: 25px;"
                          />
                          No live errors at this moment
                        </div>
                      </ion-list>
                    </div>
                    <div
                      class="slector-col-bg mt-10"
                      style="margin-left: 10px;"
                    >
                      <ion-list>
                        <ion-label class="text--white text--uppercase text-12 text--bold">
                          <i
                            class="mdi mdi-alert text--primary"
                            style="font-size: 20px; position: relative; top: 2px;"
                          />
                          Alerts
                        </ion-label>
                        <div v-if="alertsInProperty.length">
                          <ion-item
                            v-for="alert in alertsInProperty"
                            :key="alert"
                            class="flat rounded-corners"
                            lines="none"
                            button
                            @click="$router.push(`/detail/${alert.id}`)"
                          >
                            <div v-if="alert.waterLeak">
                              <div style="display: inline-block;">
                                <i
                                  class="mdi mdi-water-outline text--primary"
                                  style="font-size: 35px; position: relative; top: -8px;"
                                />
                              </div>
                              <div style="display: inline-block; padding-left: 15px; margin-top: 5px;">
                                <div>
                                  <i
                                    class="mdi mdi-view-quilt text--primary"
                                    style="font-size: 25px; position: relative; bottom: -3px; margin-right: 5px;"
                                  />
                                  <span class="text--white">{{ alert.propertyName }}</span>
                                  <span class="text--uppercase text--primary pl-10">Water Leak detected</span>
                                </div>
                                <div
                                  class="text--white mt-5 text-14"
                                >
                                  <span class="text--primary">Detected</span> {{ new Date(Number(alert.leakDetectedAt)).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}) }} @ {{ new Date(Number(alert.leakDetectedAt)).toLocaleDateString('sv-SV') }} <span class="text--primary">Duration</span> {{ Math.round(((Date.now() - Number(alert.leakDetectedAt)) / 1000) / 60) }} Minutes
                                </div>
                              </div>
                            </div>
                            <div v-if="alert.fire">
                              <div style="display: inline-block;">
                                <i
                                  class="mdi mdi-fire text--primary"
                                  style="font-size: 35px; position: relative; top: -5px;"
                                />
                              </div>
                              <div style="display: inline-block; padding-left: 15px; margin-top: 5px;">
                                <div>
                                  <i
                                    class="mdi mdi-view-quilt text--primary"
                                    style="font-size: 25px; position: relative; bottom: -3px; margin-right: 5px;"
                                  />
                                  <span class="text--white">{{ alert.propertyName }}</span>
                                  <span class="text--uppercase text--primary pl-10">Smoke detected</span>
                                </div>
                                <div
                                  class="text--white mt-5 text-14"
                                >
                                  <span class="text--primary">Detected</span> {{ new Date(Number(alert.fireDetectedAt)).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}) }} @ {{ new Date(Number(alert.fireDetectedAt)).toLocaleDateString('sv-SV') }} <span class="text--primary">Duration </span> <span class="text--white">{{ Math.round(((Date.now() - Number(alert.fireDetectedAt)) / 1000) / 60) }} Minutes</span>
                                </div>
                              </div>
                            </div>
                          </ion-item>
                        </div>
                        <div
                          v-else
                          class="mt-8 text--white"
                        >
                          <i
                            class="mdi mdi-check text--green"
                            style="font-size: 25px;"
                          />
                          No live alerts at this moment
                        </div>
                      </ion-list>
                    </div>
                    <div
                      class="slector-col-bg mt-10"
                      style="margin-left: 10px;"
                    >
                      <ion-list>
                        <ion-label class="text--white text--uppercase text-12 text--bold">
                          <i
                            class="mdi mdi-comment-alert text--primary"
                            style="font-size: 20px; position: relative; top: 2px;"
                          />
                          Warnings
                        </ion-label>
                        <div v-if="warningsInProperties.length">
                          <ion-item
                            v-for="error in warningsInProperties"
                            :key="error"
                            class="text--white flat rounded-corners"
                            lines="none"
                            button
                            @click="$router.push(`/detail/${error.propertyId}`)"
                          >
                            Battery Runnig low!!!
                          </ion-item>
                        </div>
                        <div
                          v-else
                          class="text--white"
                        >
                          <i
                            class="mdi mdi-check text--green"
                            style="font-size: 25px;"
                          /> No live warnings at this moment
                        </div>
                      </ion-list>
                    </div>
                    <div class="text--white text--uppercase text-14 text--bold mb-5 mt-30">
                      Events History Log
                    </div>
                    <div
                      class="slector-col-bg"
                      style="margin-left: 10px;"
                    >
                      <ion-item
                        v-for="alert in landlordEvents"
                        :key="alert"
                        class="flat rounded-corners"
                        lines="none"
                        button
                        @click="$router.push(`/detail/${alert.propertyId}`)"
                      >
                        <div v-if="alert.typeI === 'SensorWaterLeak'">
                          <div style="display: inline-block;">
                            <i
                              class="mdi mdi-water-outline text--primary"
                              style="font-size: 35px; position: relative; top: -8px;"
                            />
                          </div>
                          <div style="display: inline-block; padding-left: 15px; margin-top: 5px;">
                            <div>
                              <i
                                class="mdi mdi-view-quilt text--primary"
                                style="font-size: 25px; position: relative; bottom: -3px; margin-right: 5px;"
                              />
                              <span class="text--white">{{ propertyNameMapper(alert.propertyId) }}</span>
                              <span class="text--uppercase text--primary pl-10">Water Leak detected</span>
                            </div>
                            <div
                              class="text--white mt-5 text-14"
                            >
                              <span class="text--primary">Detected</span> {{ new Date(Number(alert.startTime)).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}) }} @ {{ new Date(Number(alert.startTime)).toLocaleDateString('sv-SV') }} <span class="text--primary">Duration</span> {{ Math.round((Number(alert.endTime) - Number(alert.startTime)) / 1000 / 60) }} Minutes
                            </div>
                          </div>
                        </div>
                        <div v-if="alert.typeI === 'SensorSmoke'">
                          <div style="display: inline-block;">
                            <i
                              class="mdi mdi-fire text--primary"
                              style="font-size: 35px; position: relative; top: -5px;"
                            />
                          </div>
                          <div style="display: inline-block; padding-left: 15px; margin-top: 5px;">
                            <div>
                              <i
                                class="mdi mdi-view-quilt text--primary"
                                style="font-size: 25px; position: relative; bottom: -3px; margin-right: 5px;"
                              />
                              <span class="text--white">{{ propertyNameMapper(alert.propertyId) }}</span>
                              <span class="text--uppercase text--primary pl-10">Smoke detected</span>
                            </div>
                            <div
                              class="text--white mt-5 text-14"
                            >
                              <span class="text--primary">Detected</span> {{ new Date(Number(alert.startTime)).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}) }} @ {{ new Date(Number(alert.startTime)).toLocaleDateString('sv-SV') }} <span class="text--primary">Duration </span> <span class="text--white">{{ Math.round((Number(alert.endTime) - Number(alert.startTime)) / 1000 / 60) }} Minutes</span>
                            </div>
                          </div>
                        </div>
                      </ion-item>
                      <div
                        v-if="!landlordEvents.length"
                        class="mt-8 text--white"
                      >
                        <i
                          class="mdi mdi-check text--green"
                          style="font-size: 25px;"
                        />
                        No history events at this moment
                      </div>
                    </div>
                  </div>
                  <div style="height: 100px;" />
                </div>
              </div>
            </div>
            <div
              v-else
              key="4"
            >
              <div
                class="text-left"
                style="margin-bottom: 20px;"
              >
                <span class="text-64 text--white">Add new <span class="text--primary">apartment</span></span>
              </div>
              <transition
                name="fade"
                mode="out-in"
              >
                <div
                  v-if="!created"
                  key="1"
                >
                  <ion-row style="margin-bottom: 20px;">
                    <ion-col>
                      <ion-text
                        class="text--white text-18"
                      >
                        {{ buildingDetails.length ? buildingDetails[0].buildingName : 'None' }}
                      </ion-text>
                    </ion-col>
                  </ion-row>
                  <ion-row class="pb-10">
                    <ion-col
                      size-xs="12"
                      size-xl="3"
                      class="text-left"
                    >
                      <ion-label class="text--uppercase text--bold text--white text-14">
                        Property address
                      </ion-label>
                      <div>
                        <div class="text--gray mt-5">
                          {{ buildingDetails.length ? buildingDetails[0].buildingAddress : 'None' }}, {{ buildingDetails.length ? buildingDetails[0].buildingPostCode : 'None' }}<br>
                          {{ buildingDetails.length ? buildingDetails[0].buildingCity : 'None' }}
                        </div>
                      </div>
                    </ion-col>
                    <ion-col
                      size-xl="3"
                      size-xs="12"
                      class="text-left"
                    >
                      <ion-label class="text--uppercase text--bold text--white text-14">
                        Aparments in building
                      </ion-label>
                      <div class="text--gray mt-5">
                        {{ buildingDetails.length ? buildingDetails[0].buildingFlatsPerFloor * buildingDetails[0].buildingFloors : 'None' }} from which {{ buildingProperties.length }} are created
                      </div>
                    </ion-col>
                  </ion-row>
                  <ion-row>
                    <ion-col
                      size-md="7"
                      size-xs="12"
                    >
                      <form
                        class="form--dark"
                        :class="platform.includes('ios') ? 'form--dark-ios' : 'form--dark-md'"
                      >
                        <ion-row
                          class="ion-no-padding"
                          style="margin-top: -15px;"
                        >
                          <ion-col
                            class="ion-no-padding"
                            style="margin-right: 5px;"
                          >
                            <ion-item lines="none">
                              <ion-label
                                class="label"
                                position="floating"
                              >
                                Apartment Number
                              </ion-label>
                              <ion-input
                                v-model="apartmentNumber"
                                type="number"
                                enterkeyhint="done"
                                required
                              />
                            </ion-item>
                          </ion-col>
                          <ion-col
                            class="ion-no-padding"
                            style="margin-left: 5px;"
                          >
                            <ion-item lines="none">
                              <ion-label
                                class="label"
                                position="floating"
                              >
                                Type
                              </ion-label>
                              <ion-select
                                v-model="apartmentType"
                                placeholder="Select One"
                                type="number"
                                required
                              >
                                <ion-select-option
                                  v-for="{option, name} in typeOptions"
                                  :key="option"
                                  :value="option"
                                >
                                  {{ name }}
                                </ion-select-option>
                              </ion-select>
                            </ion-item>
                          </ion-col>
                        </ion-row>
                        <ion-row
                          class="ion-no-padding"
                          style="margin-top: -15px;"
                        >
                          <ion-col
                            class="ion-no-padding"
                            style="margin-right: 5px;"
                          >
                            <ion-item lines="none">
                              <ion-label
                                class="label"
                                position="floating"
                              >
                                Apartment Name
                              </ion-label>
                              <ion-input
                                v-model="apartmentName"
                                type="text"

                                required
                              />
                            </ion-item>
                          </ion-col>
                          <ion-col
                            class="ion-no-padding"
                            style="margin-left: 5px;"
                          >
                            <ion-item lines="none">
                              <ion-label
                                class="label"
                                position="floating"
                              >
                                Floor
                              </ion-label>
                              <ion-select
                                v-model="selectedFloor"
                                placeholder="Select One"
                                type="number"
                                required
                              >
                                <ion-select-option
                                  v-for="{option, name} in floorOptions"
                                  :key="option"
                                  :value="option"
                                >
                                  {{ name }}
                                </ion-select-option>
                              </ion-select>
                            </ion-item>
                          </ion-col>
                        </ion-row>
                      </form>
                      <div>
                        <ion-item
                          lines="none"
                          color="primary"
                          style="border-radius: 5px;"
                          button
                          @click="createApartment()"
                        >
                          <ion-spinner
                            v-if="loading"
                            name="crescent"
                            style="color: #2c3036; margin: 0 auto;"
                          />
                          <ion-text
                            v-else
                            class="text--bold"
                            style="color: #2c3036; margin: 0 auto;"
                          >
                            Save
                          </ion-text>
                        </ion-item>
                        {{ message }}
                      </div>
                    </ion-col>
                    <ion-col>
                      <div class="right-padding">
                        Adding new property Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad est fuga, amet tempore perferendis aut ab fugit atque quibusdam laboriosam earum tempora placeat. Sit dolores labore corrupti, debitis rerum quibusdam.
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo vitae voluptate voluptatibus, corporis omnis ipsum quidem in sed esse enim nam? Aspernatur provident tempore sunt delectus aut unde eligendi nisi!
                      </div>
                    </ion-col>
                  </ion-row>
                </div>
                <div
                  v-else
                  style="margin-top: 25vh;"
                >
                  <span class="text-64 text--white"><i
                    class="mdi mdi-check-circle"
                    style="color: lightgreen;"
                  />Apartment Created</span>
                </div>
              </transition>
            </div>
          </transition>
        </div>
      </transition>
    </ion-content>
    <!-- </div> -->
  </ion-page>
</template>

<script>
import { IonProgressBar, actionSheetController, modalController } from '@ionic/vue'
import { mapState } from 'vuex'
import { addOutline } from 'ionicons/icons'

import VueApexCharts from 'vue3-apexcharts'
import { DatePicker } from 'v-calendar'
import dayjs from 'dayjs'

import editBuildingDetails from '../components/Landlords/LandlordEditBuildingDetails.vue'
import buildingSpecificSettings from '../components/Landlords/LandlordBuildingSpecificSettings.vue'

const customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)

export default {
  components: {
    IonProgressBar,
    apexchart: VueApexCharts,
    datePicker: DatePicker
  },
  data () {
    return {
      loading: false,
      buildingId: null,
      loadingCharts: true,
      disconnectedDevicesDetail: [],
      dataDateRangeSelectorWeek: {
        start: new Date(new Date() - (7 * 86400000)).setHours(0, 0, 0),
        end: new Date()
      },
      selectedView: '1',
      selectedFlat: 'All',
      selectedFlor: 'All',
      chartsView: 'all',
      chartsViewAction: 'all',
      selectedTimePeriod: 'Last 7 days',
      timePeriodSelector: [
        { name: 'Today' },
        { name: 'Last 7 days' },
        { name: 'This month' },
        { name: 'Last month' },
        { name: 'This year' }
      ],
      selectedViewApartment: '1',
      buildingDetails: null,
      buildingProperties: null,
      buildingDevices: null,
      created: false,
      selectedFilterPlaceType: 'All',
      search: '',
      selectedFloor: '',
      apartmentNumber: '',
      apartmentName: '',
      apartmentType: '',
      averageTemp: 0,
      message: '',
      addOutline,
      datacollection: null,
      indoorVsOutdoor: {
        title: {
          text: 'Indoor vs Outdoor temperature',
          align: 'center',
          offsetY: 10,
          style: {
            fontSize: '14px',
            fontWeight: 'bold',
            color: '#ffffff'
          }
        },
        colors: ['#0290ff', '#51FF00', '#e9d47d', '#6aaad5', '#c8655b'],
        chart: {
          id: 'Temperature Outdoor-Indoor',
          type: 'line',
          toolbar: {
            color: 'white',
            offsetY: 10,
            offsetX: -30,
            tools: {
              download: true
            }
          },
          zoom: {
            enabled: false
          },
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350
            }
          }
        },
        stroke: {
          show: true,
          curve: 'smooth',
          lineCap: 'butt',
          colors: undefined,
          width: 2,
          dashArray: [0, 0, 2, 5, 5]
        },
        xaxis: {
          labels: {
            formatter: (value) => {
              const i = String(value).split('/')
              if (i.length > 1) {
                return i[0] + ' / ' + i[1]
              } else {
                return value
              }
            },
            style: {
              colors: 'white',
              fontSize: '12px',
              cssClass: 'apexcharts-xaxis-label'
            }
          }
        },
        yaxis: {
          labels: {
            formatter: (value) => value ? value.toFixed(0) + ' °C' : '',
            style: {
              colors: 'white',
              fontSize: '12px',
              cssClass: 'apexcharts-xaxis-label'
            }
          }
        },
        legend: {
          fontSize: '14px',
          labels: {
            colors: 'white'
          }
        },
        tooltip: {
          theme: 'dark',
          y: {
            formatter: (value) => value === null ? 'no data' : value + ' °C'
          }
        },
        annotations: {
          position: 'front',
          xaxis: [
            {
              x: dayjs().format('DD / MM'),
              borderColor: 'white',
              strokeDashArray: 3,
              borderWidth: 1,
              label: {
                offsetY: 10,
                offsetX: 0,
                // text: 'Target',
                style: {
                  color: '#2c3036',
                  fontSize: '12px',
                  background: 'white'
                }
              }
            }
          ]
        },
        grid: {
          borderColor: '#616161'
        }
      },
      indoorVsOutdoorSerie: [
        {
          name: 'Outdoor',
          data: [
            { x: '05/06/2021', y: 13 },
            { x: '06/06/2021', y: 12 },
            { x: '07/06/2021', y: 16 },
            { x: '08/06/2021', y: 20 },
            { x: '09/06/2021', y: 21 },
            { x: '10/06/2021', y: 22 },
            { x: '11/06/2021', y: 23 },
            { x: '12/06/2021', y: 19 }
          ]
        },
        {
          name: 'Indoor',
          data: [
            { x: '05/06/2021', y: 21 },
            { x: '06/06/2021', y: 22 },
            { x: '07/06/2021', y: 23 },
            { x: '08/06/2021', y: 19 },
            { x: '09/06/2021', y: 20 },
            { x: '10/06/2021', y: 18 },
            { x: '11/06/2021', y: 23 },
            { x: '12/06/2021', y: 20 }
          ]
        }
      ],
      targetTemp: {
        title: {
          text: 'Temperature Spread',
          align: 'center',
          offsetY: 10,
          style: {
            fontSize: '14px',
            fontWeight: 'bold',
            color: '#ffffff'
          }
        },
        colors: ['#001BFF', '#00C5FF', '#00FFFB', '#00FF46', '#F7FF00', '#FFB900', '#FF0000'],
        chart: {
          id: 'Temperature Spread',
          type: 'bar',
          toolbar: {
            color: 'white',
            offsetY: 10,
            offsetX: -30,
            tools: {
              download: true
            }
          }
        },
        tooltip: {
          theme: 'dark',
          x: {
            formatter: (value) => { return 'Temperature ' + value }
          }
        },
        grid: {
          borderColor: '#616161'
        },
        plotOptions: {
          bar: {
            columnWidth: '35%',
            distributed: true,
            dataLabels: {
              enabled: true,
              position: 'top'
            }
          }
        },
        dataLabels: {
          formatter: (value) => value ? value.toFixed(0) + ' %' : '',
          enabled: true,
          offsetY: -25
        },
        legend: {
          show: false
        },
        annotations: {
          position: 'front',
          xaxis: [
            {
              x: '21°C',
              borderColor: 'white',
              strokeDashArray: 5,
              borderWidth: 2,
              label: {
                offsetY: 10,
                offsetX: 0,
                // text: 'Target',
                style: {
                  color: '#2c3036',
                  fontSize: '12px',
                  background: 'white'
                }
              }
            }
          ]
        },
        xaxis: {
          categories: ['18°C', '19°C', '20°C', '21°C', '22°C', '23°C', '24°C'],
          formatter: (value) => value ? value.toFixed(0) + ' %' : '',
          labels: {
            style: {
              colors: 'white',
              fontSize: '12px'
            }
          }
        },
        yaxis: {
          labels: {
            formatter: (value) => value ? value.toFixed(0) + ' %' : '',
            style: {
              colors: 'white',
              fontSize: '12px',
              cssClass: 'apexcharts-xaxis-label'
            }
          }
        }
      },
      targetTempSeries: [
        {
          name: 'Percentage',
          data: [0, 0, 1, 5, 2, 5, 10]
        }
      ],
      typeSelector: [
        { name: 'All Types', value: 'All' },
        { name: 'Residental', value: 'Residental' },
        { name: 'Common Area', value: 'Common Area' },
        { name: 'Comercial', value: 'Comercial' },
        { name: 'Utility', value: 'Utility' }
      ],
      tempPerFloor: {
        title: {
          text: 'Temperature per each floor',
          align: 'center',
          offsetY: 10,
          style: {
            fontSize: '14px',
            fontWeight: 'bold',
            color: '#ffffff'
          }
        },
        colors: ['#51FF00', '#FFEC00', '#1E90FF', '#9400D3', '#FF8C00'],
        chart: {
          id: 'Temperature per each floor',
          type: 'line',
          toolbar: {
            color: 'white',
            offsetY: 10,
            offsetX: -30,
            tools: {
              download: true
            }
          },
          zoom: {
            enabled: false
          },
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350
            }
          }
        },
        stroke: {
          show: true,
          curve: 'smooth',
          lineCap: 'butt',
          colors: undefined,
          width: 2,
          dashArray: 0
        },
        xaxis: {
          labels: {
            style: {
              colors: 'white',
              fontSize: '12px',
              cssClass: 'apexcharts-xaxis-label'
            }
          }
        },
        yaxis: {
          decimalsInFloat: 1,
          labels: {
            formatter: (value) => value ? value.toFixed(0) + ' °C' : '',
            style: {
              colors: 'white',
              fontSize: '12px',
              cssClass: 'apexcharts-xaxis-label'
            }
          }
        },
        legend: {
          fontSize: '14px',
          labels: {
            colors: 'white'
          }
        },
        tooltip: {
          theme: 'dark',
          y: {
            formatter: (value) => value === null ? 'no data' : value + ' °C'
          }
        },
        grid: {
          borderColor: '#616161'
        }
      },
      tempPerFloorSeries: [
        {
          name: 'Floor 1',
          data: [
            { x: '05/06/2021', y: 13 },
            { x: '06/06/2021', y: 12 },
            { x: '07/06/2021', y: 16 },
            { x: '08/06/2021', y: 20 },
            { x: '09/06/2021', y: 21 },
            { x: '10/06/2021', y: 22 },
            { x: '11/06/2021', y: 23 },
            { x: '12/06/2021', y: 19 }
          ]
        },
        {
          name: 'Floor 2',
          data: [
            { x: '05/06/2021', y: 21 },
            { x: '06/06/2021', y: 22 },
            { x: '07/06/2021', y: 23 },
            { x: '08/06/2021', y: 19 },
            { x: '09/06/2021', y: 20 },
            { x: '10/06/2021', y: 18 },
            { x: '11/06/2021', y: 23 },
            { x: '12/06/2021', y: 20 }
          ]
        }
      ],
      tempPerApartment: {
        title: {
          text: 'Average temperature per apartment',
          align: 'center',
          offsetY: 10,
          style: {
            fontSize: '14px',
            fontWeight: 'bold',
            color: '#ffffff'
          }
        },
        colors: ['#51FF00', '#FFEC00', '#1E90FF', '#9400D3', '#FF8C00'],
        chart: {
          id: 'Average temperature per apartment',
          type: 'bar',
          toolbar: {
            color: 'white',
            offsetY: 10,
            offsetX: -30,
            tools: {
              download: true
            }
          },
          zoom: {
            enabled: false
          },
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350
            }
          }
        },
        stroke: {
          show: true,
          curve: 'smooth',
          lineCap: 'butt',
          colors: undefined,
          width: 2,
          dashArray: 0
        },
        xaxis: {
          labels: {
            show: false
          }
        },
        yaxis: {
          tickAmount: 5,
          labels: {
            formatter: (value) => value + ' °C',
            style: {
              colors: 'white',
              fontSize: '12px',
              cssClass: 'apexcharts-xaxis-label'
            }
          }
        },
        legend: {
          fontSize: '14px',
          labels: {
            colors: 'white'
          }
        },
        tooltip: {
          theme: 'dark',
          y: {
            formatter: (value) => value + ' °C'
          },
          title: {
            formatter: (seriesName) => seriesName
          }
        },
        grid: {
          borderColor: '#616161'
        },
        plotOptions: {
          bar: {
            columnWidth: '35%',
            distributed: true,
            dataLabels: {
              enabled: true,
              position: 'top'
            }
          }
        },
        annotations: {
          position: 'front',
          yaxis: [
            {
              y: 21,
              borderColor: 'white',
              strokeDashArray: 1,
              borderWidth: 2,
              label: {
                offsetY: 10,
                offsetX: 0,
                style: {
                  color: '#2c3036',
                  fontSize: '12px',
                  background: 'white'
                }
              }
            }
          ]
        }
      },
      tempPerApartmentSeries: [
        {
          name: 'Apartment average',
          data: [
            {
              id: 13,
              x: 'Apartment 1',
              y: 22.4
            },
            {
              id: 14,
              x: 'Apartment 2',
              y: 22.3
            }
          ]
        }
      ],
      typeOptions: [
        { option: 'Comercial', name: 'Comercial' },
        { option: 'Residental', name: 'Residental' },
        { option: 'Utility', name: 'Utility' },
        { option: 'Common Area', name: 'Common Area' }
      ],
      heatMapPerApartment: {
        title: {
          text: 'Heatmap',
          align: 'center',
          offsetY: 10,
          style: {
            fontSize: '14px',
            fontWeight: 'bold',
            color: '#ffffff'
          }
        },
        chart: {
          type: 'heatmap',
          id: 'heatmap',
          toolbar: {
            color: 'white',
            offsetY: 10,
            offsetX: -30,
            tools: {
              download: true
            }
          }
        },
        plotOptions: {
          heatmap: {
            shadeIntensity: 0.5,
            distributed: true,
            radius: 0,
            useFillColorAsStroke: false,
            colorScale: {
              ranges: [
                {
                  from: 0,
                  to: 1,
                  name: 'No data',
                  color: '#FFFFFF'
                },
                {
                  from: 1.1,
                  to: 18.5,
                  name: '< 18.5',
                  color: '#8934eb'
                },
                {
                  from: 18.6,
                  to: 19,
                  name: '18.5-19',
                  color: '#3734eb'
                },
                {
                  from: 19.1,
                  to: 19.5,
                  name: '19-19.5',
                  color: '#34abeb'
                },
                {
                  from: 19.6,
                  to: 20,
                  name: '19.5-20',
                  color: '#34dfeb'
                },
                {
                  from: 20.1,
                  to: 20.5,
                  name: '20-20.5',
                  color: '#34ebb4'
                },
                {
                  from: 20.6,
                  to: 21,
                  name: '20.5-21',
                  color: '#34eb6e'
                },
                {
                  from: 21.1,
                  to: 21.5,
                  name: '21-21.5',
                  color: '#34eb3a'
                },
                {
                  from: 21.6,
                  to: 22,
                  name: '21.5-22',
                  color: '#80eb34'
                },
                {
                  from: 22.1,
                  to: 22.5,
                  name: '22-22.5',
                  color: '#ebdc34'
                },
                {
                  from: 22.6,
                  to: 23,
                  name: '22.5-23',
                  color: '#ebae34'
                },
                {
                  from: 23.1,
                  to: 50,
                  name: '23 >',
                  color: '#eb5934'
                }
              ]
            }
          }
        },
        toolbar: {
          color: 'white',
          offsetY: 10,
          offsetX: -10,
          tools: {
            download: true
          }
        },
        zoom: {
          enabled: false
        },
        legend: {
          fontSize: '14px',
          labels: {
            colors: 'white'
          }
        },
        yaxis: {
          labels: {
            style: {
              colors: 'white',
              fontSize: '12px',
              cssClass: 'apexcharts-xaxis-label'
            }
          }
        },
        xaxis: {
          labels: {
            style: {
              colors: 'white',
              fontSize: '12px',
              cssClass: 'apexcharts-xaxis-label'
            }
          }
        },
        tooltip: {
          theme: 'dark',
          y: {
            formatter: (value) => value === 0 ? 'no data' : value + ' °C'
          },
          title: {
            formatter: (seriesName) => seriesName
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 1,
          colors: ['#e6e6e6']
        }
      },
      heatMapPerApartmentSeries: [
        {
          name: '00:00-01:00',
          data: [
            { x: '20/07/2021', y: 23 }
          ]
        }
      ]
    }
  },
  computed: {
    ...mapState(['landlordBuildings', 'landlordProperties', 'landlordDevices']),
    floorOptions () {
      const floorsAvailable = this.buildingDetails.length ? this.buildingDetails[0].buildingFloors : 0
      // console.log(floorsAvailable)
      const result = []
      for (let i = 0; i <= floorsAvailable; i++) {
        if (i === 1) {
          const first = {
            option: '1',
            name: '1st Floor'
          }
          result.push(first)
        } else if (i === 2) {
          const second = {
            option: '2',
            name: '2nd Floor'
          }
          result.push(second)
        } else if (i === 3) {
          const third = {
            option: '3',
            name: '3rd Floor'
          }
          result.push(third)
        } else {
          const others = {
            option: String(i),
            name: `${i}th Floor`
          }
          result.push(others)
        }
      }
      return result
    },
    selectorProperties () {
      const properties = this.buildingProperties
      const filter = this.selectedFlor
      let propertiesAfterFilter = []
      if (filter === 'All') {
        propertiesAfterFilter = properties
      } else {
        // console.log(properties)
        propertiesAfterFilter = properties.filter(item => Number(item.propertyFloor) === filter)
      }
      return propertiesAfterFilter
    },
    filterProperties () {
      const properties = this.buildingProperties
      const filter = this.selectedFilterPlaceType
      let propertiesAfterFilter = []
      if (filter === 'All') {
        propertiesAfterFilter = properties
      } else {
        propertiesAfterFilter = properties.filter(item => item.propertyType === filter)
      }
      return propertiesAfterFilter.filter(item => {
        return item.flatNumber.toLowerCase().includes(this.search.toLowerCase()) ||
               item.residentName.toLowerCase().includes(this.search.toLowerCase()) ||
               item.propertyName.toLowerCase().includes(this.search.toLowerCase())
      })
    },
    errorsInProperties () {
      const buildingId = this.buildingDetails[0].id
      const result = []
      this.landlordProperties.forEach(item => {
        if (item.gatewayActive === false && item.buildingId === buildingId) { result.push(item) }
      })
      this.landlordDevices.forEach(item => {
        if (!item.active && item.buildingId === buildingId) { result.push(item) }
      })
      // console.log(result)
      return result
    },
    warningsInProperties () {
      const devices = this.buildingDevices
      const id = this.buildingDetails[0].id
      const lowBattery = devices.filter(item => item.battery === 1 && item.buildingId === id)
      return lowBattery
    },
    alertsInProperty () {
      const properties = this.buildingProperties
      const id = this.buildingDetails[0].id
      const result = []
      properties.forEach(item => {
        if (item.fire === true && item.buildingId === id) {
          const alert = {
            id: item.id,
            propertyName: item.propertyName,
            fire: item.fire
          }
          result.push(alert)
        } else if (item.waterLeak === true && item.buildingId === id) {
          const alert = {
            id: item.id,
            propertyName: item.propertyName,
            waterLeak: item.waterLeak,
            leakDetectedAt: item.leakDetectedAt
          }
          result.push(alert)
        }
      })
      // console.log(result)
      return result
    },
    eventsInPropertyCount () {
      const sum = this.alertsInProperty.length + this.warningsInProperties.length + this.errorsInProperties.length
      // console.log('summary', sum)
      return sum
    },
    platform () {
      // console.log(this.$store.state.platform)
      return this.$store.state.platform
    },
    tempPerApartmentBelowAverage () {
      const data = this.tempPerApartmentSeries[0].data
      const result = []
      data.forEach(item => {
        if (item.y <= this.averageTemp.average) {
          result.push(item)
        }
      })
      return result
    },
    tempPerApartmentAboveAverage () {
      const data = this.tempPerApartmentSeries[0].data
      const result = []
      data.forEach(item => {
        if (item.y > this.averageTemp.average) {
          result.push(item)
        }
      })
      return result
    },
    tempPerApartmentMinAverage () {
      const data = this.tempPerApartmentSeries[0].data
      // console.log(data)
      const length = data.length
      let tempMin = 0
      let tempMax = 0
      data.forEach(item => {
        tempMin = tempMin + item.min
        tempMax = tempMax + item.max
      })
      // console.log(tempMin)
      const result = {
        minAverage: tempMin / length,
        maxAverage: tempMax / length
      }
      return result
    },
    landlordEvents () {
      const events = this.$store.state.landlordEvents
      const result = []
      events.last31DaysFireEvent.forEach(item => {
        if (item.buildingId === Number(this.buildingId)) { result.push(item) }
      })
      events.last31daysLeakEvent.forEach(item => {
        if (item.buildingId === Number(this.buildingId)) { result.push(item) }
      })
      // console.log(result)
      result.sort(function (a, b) {
        return b.id - a.id
      })
      return result
    }
  },
  watch: {
    '$route.params.id': function (id) {
      if (this.$route.name === 'LandlordPropertyDetail') {
        if (id) {
          this.buildingId = id
          this.getBuildingData(id)
          this.clearPage()
        }
      }
    },
    apartmentNumber: function () {
      if (this.apartmentNumber) {
        this.apartmentName = 'Apartment ' + this.apartmentNumber
      }
    },
    selectedTimePeriod: function (value) {
      if (value === 'Today') {
        this.todaysDate()
      } else if (value === 'Last 7 days') {
        this.lastWeek()
      } else if (value === 'This month') {
        this.thisMonth()
      } else if (value === 'Last month') {
        this.lastMonth()
      } else if (value === 'This year') {
        this.thisYear()
      }
    },
    dataDateRangeSelectorWeek: function (value) {
      if (dayjs(value.start).format('DD/MM/YYYY') === dayjs(value.end).format('DD/MM/YYYY')) {
        this.selectedTimePeriod = 'Custom day'
      }
    },
    selectedFlat: function (value) {
      if (value !== 'All') {
        this.chartsView = 'flat'
      } else if (value === 'All' && this.selectedFlor !== 'All') {
        this.chartsView = 'floor'
      } else {
        this.chartsView = 'all'
      }
    },
    selectedFlor: function (value) {
      this.selectedFlat = 'All'
      if (value !== 'All' && this.selectedFlat === 'All') {
        this.chartsView = 'floor'
      } else if (value === 'All' && this.selectedFlat !== 'All') {
        this.chartsView = 'flat'
      } else {
        this.chartsView = 'all'
      }
    }
  },
  created () {
    this.getBuildingData(this.$route.params.id)
    this.buildingId = this.$route.params.id
  },
  mounted () {
    this.$store.dispatch('getDisconnectionDetail', this.buildingId)
      .then((resp) => {
        this.disconnectedDevicesDetail = resp.data
      })
      .catch(error => {
        console.log(error)
      })
  },
  methods: {
    getBuildingData (buildingId) {
      this.loading = true
      this.$store.dispatch('getLandlordBuildingsAndProperties').then(response => {
        const id = Number(buildingId)
        const landlordProperties = this.landlordProperties
        this.buildingProperties = landlordProperties.filter(item => item.buildingId === id)
        const landlordBuildings = this.landlordBuildings
        this.buildingDetails = landlordBuildings.filter(item => item.id === id)
        this.targetTemp.annotations.xaxis[0].x = this.buildingDetails[0].targetTemperature + '°C'
        this.tempPerApartment.annotations.yaxis[0].y = this.buildingDetails[0].targetTemperature
        const landlordDevices = this.landlordDevices
        this.buildingDevices = landlordDevices.filter(item => item.buildingId === id)
        this.getDataForCharts()
        this.loading = false
      })
    },
    battery (id) {
      const devices = this.buildingDevices
      const lowBattery = devices.filter(item => item.battery === 1 && item.propertyId === id)
      return lowBattery.length
    },
    errors () {
      const properties = this.buildingProperties
      const devices = this.buildingDevices
      const id = this.buildingDetails[0].id
      let result = 0
      properties.forEach(item => {
        if (item.gatewayActive === false && item.buildingId === id) {
          result++
        }
      })
      devices.forEach(item => {
        if (item.active === false && item.buildingId === id) {
          result++
        }
      })
      return result
    },
    errorsInProperty (id) {
      const properties = this.buildingProperties
      const devices = this.buildingDevices
      let result = 0
      properties.forEach(item => {
        if (item.gatewayActive === false && item.id === id) {
          result++
        }
      })
      devices.forEach(item => {
        if (item.active === false && item.propertyId === id) {
          result++
        }
      })
      return result
    },
    alerts () {
      const properties = this.buildingProperties
      const id = this.buildingDetails[0].id
      let count = 0
      properties.forEach(item => {
        if (item.fire === true && item.buildingId === id) {
          count++
        } else if (item.waterLeak === true && item.buildingId === id) {
          count++
        }
      })
      return count
    },
    warnings () {
      const devices = this.buildingDevices
      const id = this.buildingDetails[0].id
      const lowBattery = devices.filter(item => item.battery === 1 && item.buildingId === id)
      return lowBattery.length
    },
    refreshProperties (id) {
      const landlordProperties = this.landlordProperties
      this.buildingProperties = landlordProperties.filter(item => item.buildingId === id)
    },
    createApartment () {
      this.loading = true
      const data = {
        apartmentName: this.apartmentName,
        apartmentNumber: this.apartmentNumber,
        apartmentType: this.apartmentType,
        apartmentFloor: this.selectedFloor,
        apartmentAddress: this.buildingDetails[0].buildingAddress,
        apartmentCity: this.buildingDetails[0].buildingCity,
        apartmentPostCode: this.buildingDetails[0].buildingPostCode,
        landlordId: this.buildingDetails[0].landlordId,
        buildingId: this.buildingDetails[0].id
      }
      // console.log(data)
      this.$store.dispatch('landlordCreateApartment', data).then(response => {
        if (response) {
          this.created = true
          setTimeout(() => { this.created = false }, 2000)
          this.loading = false
          this.refreshProperties(data.buildingId)
          this.selectedView = '1'
        }
      })
    },
    clearPage () {
      this.selectedFloor = ''
      this.appartmentNumber = ''
      this.apartmentName = ''
      this.apartmentType = ''
      this.message = ''
      this.buildingProperties = ''
      // this.selectedView = '1'
      // this.selectedViewApartment = '1'
    },
    todaysDate () {
      const today = new Date()
      this.dataDateRangeSelectorWeek = {
        start: today,
        end: today
      }
    },
    lastWeek () {
      const today = new Date()
      const todayMinus7Days = new Date(new Date() - (7 * 86400000))
      this.dataDateRangeSelectorWeek = {
        start: todayMinus7Days,
        end: today
      }
    },
    thisMonth () {
      const today = new Date()
      const firstDay = new Date(today.getFullYear(), today.getMonth(), 1)
      const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0)
      this.dataDateRangeSelectorWeek = {
        start: firstDay,
        end: lastDay
      }
    },
    lastMonth () {
      const today = new Date()
      today.setDate(1)
      today.setMonth(today.getMonth() - 1)
      const firstDay = new Date(today.getFullYear(), today.getMonth(), 1)
      const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0)
      // console.log(firstDay)
      this.dataDateRangeSelectorWeek = {
        start: firstDay,
        end: lastDay
      }
    },
    thisYear () {
      const firstDay = new Date(new Date().getFullYear(), 0, 1)
      const lastDay = new Date()
      // console.log(firstDay)
      this.dataDateRangeSelectorWeek = {
        start: firstDay,
        end: lastDay
      }
    },
    getDataForCharts () {
      this.loadingCharts = true
      this.chartsViewAction = this.chartsView
      const dateRange = this.dataDateRangeSelectorWeek
      dateRange.start = new Date(dateRange.start).setHours(0, 0, 0)
      dateRange.end = new Date(dateRange.end).setHours(0, 0, 0)

      const buildingCity = this.buildingDetails ? this.buildingDetails[0].buildingCity : null
      console.log(buildingCity)
      const bulidngId = this.buildingDetails[0].id
      this.$store.dispatch('getChartDataForProperty', { id: bulidngId, dateRange, selectedFlat: this.selectedFlat, selectedFloor: this.selectedFlor, buildingCity }).then(resp => {
        if (resp) {
          // console.log(resp)
          // console.log(this.selectedTimePeriod)
          const indoorVsOutdoor = resp.data.resultOutdoorVsIndoor
          if (this.selectedTimePeriod === 'Today' || this.selectedTimePeriod === 'Custom day') {
            indoorVsOutdoor[0].data.sort((a, b) => dayjs('2021/01/01 ' + a.x) - dayjs('2021/01/01 ' + b.x))
            indoorVsOutdoor[1].data.sort((a, b) => dayjs('2021/01/01 ' + a.x) - dayjs('2021/01/01 ' + b.x))
          } else {
            indoorVsOutdoor[0].data.sort((a, b) => dayjs(a.x, 'DD/MM/YYYY') - dayjs(b.x, 'DD/MM/YYYY'))
            indoorVsOutdoor[1].data.sort((a, b) => dayjs(a.x, 'DD/MM/YYYY') - dayjs(b.x, 'DD/MM/YYYY'))
          }
          // console.log(indoorVsOutdoor)
          this.indoorVsOutdoorSerie = indoorVsOutdoor
          this.targetTempSeries = resp.data.targetTemp
          this.averageTemp = resp.data.averageTemp
          const sortedPerFloorSeries = resp.data.resultTempPerFloor.sort((a, b) => a.id - b.id)

          if (this.selectedTimePeriod === 'Today' || this.selectedTimePeriod === 'Custom day') {
            sortedPerFloorSeries.forEach(item => {
              item.data.sort((a, b) => dayjs('2021/01/01 ' + a.x) - dayjs('2021/01/01 ' + b.x))
            })
          } else {
            sortedPerFloorSeries.forEach((item, i) => {
              item.data.sort((a, b) => dayjs(a.x, 'DD/MM/YYYY') - dayjs(b.x, 'DD/MM/YYYY'))
            })
          }
          this.tempPerFloorSeries = sortedPerFloorSeries
          const sortedPerApartmentSeries = resp.data.perApartment.sort((a, b) => a.id - b.id)
          sortedPerApartmentSeries[0].data.sort((a, b) => a.id - b.id)
          this.tempPerApartmentSeries = sortedPerApartmentSeries
          const sortedHeatMap = resp.data.flatHeatMapResponse.sort((a, b) => a.id - b.id)
          sortedHeatMap.forEach(item => {
            item.data.sort((a, b) => new Date(a.id) - new Date(b.id))
          })
          this.heatMapPerApartmentSeries = sortedHeatMap
          this.loadingCharts = false
        } else {
          this.loadingCharts = false
        }
      })
    },
    deviceNameMapper (zigbeeModel) {
      // console.log(zigbeeModel)
      if (zigbeeModel === 'SensorSmoke') {
        return 'Smoke Sensor'
      } else if (zigbeeModel === 'SensorWaterLeak') {
        return 'Leak Sensor'
      } else if (zigbeeModel === 'SensorMotion') {
        return 'Multi Sensor'
      } else if (zigbeeModel === 'SensorTemperatureIndoor') {
        return 'Indoor Temp/Humi Sensor'
      } else if (zigbeeModel === 'SensorTemperatureOutdoor') {
        return 'Outdoor Temp/Humi Sensor'
      } else {
        return zigbeeModel
      }
    },
    propertyNameMapper (propertyId) {
      const property = this.landlordProperties.filter(item => item.id === propertyId)
      return property[0].propertyName
    },
    async openBuildingMenu (building) {
      const actionSheet = await actionSheetController
        .create({
          cssClass: 'my-custom-class',
          buttons: [
            {
              text: this.$t('Edit building details'),
              handler: () => {
                this.editBuildingDetails(building)
              }
            },
            {
              text: this.$t('Building specific settings'),
              handler: () => {
                this.buildingSpecificSettings(building)
              }
            },
            {
              text: this.$t('Remove all apartments'),
              role: 'destructive',
              handler: () => {
              }
            },
            {
              text: this.$t('Remove building'),
              role: 'destructive',
              handler: () => {
              }
            },
            {
              text: this.$t('Cancel'),
              role: 'cancel',
              handler: () => {
                // // console.log('Cancel clicked')
              }
            }
          ]
        })
      await actionSheet.present()
      const { role } = await actionSheet.onDidDismiss()
      console.log('onDidDismiss resolved with role', role)
    },
    async editBuildingDetails (building) {
      const modal = await modalController
        .create({
          component: editBuildingDetails,
          cssClass: 'devices-modal',
          showBackdrop: true,
          translucent: true,
          componentProps: {
            refreshData: (id) => this.getBuildingData(id),
            close: () => modal.dismiss(),
            building: building
          }
        })
      await modal.present()
    },
    async buildingSpecificSettings (building) {
      const modal = await modalController
        .create({
          component: buildingSpecificSettings,
          cssClass: 'devices-modal',
          showBackdrop: true,
          translucent: true,
          componentProps: {
            refreshData: (id) => this.getBuildingData(id),
            close: () => modal.dismiss(),
            building: building
          }
        })
      await modal.present()
    },
    fireSensorExists (id) {
      let result = false
      const smokeSensor = this.buildingDevices.filter(item => item.propertyId === id && item.typeI === 'SensorSmoke')
      if (smokeSensor.length) {
        result = true
      }
      return result
    },
    waterLeakExists (id) {
      let result = false
      const waterLeakSensor = this.buildingDevices.filter(item => item.propertyId === id && item.typeI === 'SensorWaterLeak')
      if (waterLeakSensor.length) {
        result = true
      }
      return result
    },
    getDisconnectionDetail (deviceDisconnected) {
      let result = { startTime: null }
      result = this.disconnectedDevicesDetail.filter(item => item.ieee === deviceDisconnected.ieee)[0]

      return result
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/theme/_colors';
@import "~@/theme/_backgrounds";
ion-fab {
  bottom: 50px;
  right: 20px;
}
ion-progress-bar {
    --background: gray;
}
// .container2 {
//   // padding-left: 2vw;
//   // padding-right: 2vw;
// }
.text-64 {
    font-size: 36px;
}
.top {
  width: 100%;
  margin-bottom: 30px;
  display: inline-flex;
  left: 0;
}
.search-bar-wrapper {
  max-width: 350px;
}
.search-bar {
  --box-shadow: none;
  color: white;
  --background: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.flat {
    margin-top: 8px;
   @include bg-box-item;
   --min-height: 60px;
}
.right-padding {
    color: gray;
    border: 1px solid rgba(128, 128, 128, 0.356);
    border-radius: 5px;
    padding: 5px;
    margin: 15px;
}
.apartment-icon {
  font-size: 30px;
}
.line {
  border-bottom: 1px solid rgba(255, 255, 255, 0.06);
}
.chart-col-bg {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  min-height: 325px;
}

.slector-col-bg {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 10px;
}

input {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border: none;
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  border-radius: 5px;
}

.no-outline:focus {
  outline: none;
}
.display-data-button {
  background-color: #2c3036b2;
  max-width: 120px;
  max-height: 27px;
  font-weight: 400;
}
.filter-data-button {
  max-width: 100px;
  max-height: 27px;
  font-weight: 400;
}
.h-45 {
   --min-height: 45px;
   @include bg-box-item;
}
.red-border {
  border: 1px solid rgba(255, 0, 0, 0.7);
}
.orange-border {
  border: 1px solid rgba(255, 166, 0, 0.7);
}

.selector {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 0;
  height: 30px;
  margin-top: 10px;
  margin-right: 5px;
  --padding-end: 0px;
  --min-height: 30px;
  .in-item {
    --padding-top: 4px;
    --padding-end: 0px;
  }
  ion-label {
    margin-top: 5px;
    --padding-end: 0px;
  }
  i {
    margin-inline-start: 2px;
    margin-inline-end: 0px;
    margin-bottom: 4px;
  }
}
.rounded-corners-top {
  border-radius: 10px 10px 0 0;
}
.average-temperature {
  background-color: rgba(255, 255, 255, 0.1);
}
.average-temperature-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
ion-fab {
  position: fixed;
}
.element-background {
 background:  rgba(0, 0, 0, 0.1);
  margin-left: 20px;
  margin-right: 20px;
  padding: 20px;
  border-radius: 10px;
}
</style>