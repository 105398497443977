<template>
  <div class="background--modal-landlord" />
  <ion-header class="ion-no-border">
    <ion-toolbar color="transparent">
      <div slot="start">
        <ion-button
          v-if="selectedView === '1' && '2'"
          fill="clear"
          color="transparent"
          size="large"
          class="close-modal-button"
          @click="dismissModal(buildingId)"
        >
          <img
            src="../../assets/images/arrow-go-back-line.svg"
          >
        </ion-button>
        <ion-button
          v-else
          fill="clear"
          color="transparent"
          size="large"
          class="close-modal-button"
          @click="selectedView = '1'"
        >
          <img
            src="../../assets/images/arrow-go-back-line.svg"
          >
        </ion-button>
      </div>
      <div>
        <ion-title
          class="text--white text-18"
        >
          {{ building.buildingName }}
        </ion-title>
      </div>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <div v-if="!created">
      <div class="wrapper">
        <form
          class="form--dark"
          :class="platform.includes('ios') ? 'form--dark-ios' : 'form--dark-md'"
        >
          <ion-row
            class="ion-no-padding"
            style="margin-top: -15px;"
          >
            <ion-col
              class="ion-no-padding"
              style="margin-left: 5px;"
            >
              <ion-item lines="none">
                <ion-label
                  class="label"
                  style="margin-top: 5px;"
                >
                  <span class="text--primary">Building Target temperature</span>
                </ion-label>
                <ion-select
                  v-model="targetTemperature"
                  placeholder="Select One"
                  type="number"
                  required
                  style="margin-top: 5px;"
                >
                  <ion-select-option
                    v-for="{value, name} in targetTempOptions"
                    :key="value"
                    :value="value"
                  >
                    {{ name }}
                  </ion-select-option>
                </ion-select>
                <i class="mdi mdi-chevron-down text--gray pl-5" />
              </ion-item>
            </ion-col>
          </ion-row>
        </form>
        <ion-button
          class="button-tenant mt-30"
          :disabled="loading"
          @click="saveEditedDetails"
        >
          <ion-spinner
            v-if="loading"
            name="crescent"
          />
          <span v-else>Save building settings</span>
        </ion-button>
      </div>
    </div>
    <div
      v-else
      style="margin-top: 20vh;"
    >
      <span class="text-64 text--white"><i
        class="mdi mdi-check-circle"
        style="color: lightgreen;"
      /> Tenant Invited</span>
    </div>
  </ion-content>
</template>

<script>
import { defineComponent } from 'vue'
import { } from '@ionic/vue'

export default defineComponent({
  name: 'Popover',
  props: {
    refreshData: { type: Function, default: null },
    close: { type: Function, default: null },
    building: { type: Object, default: () => {} }
  },
  data () {
    return {
      loading: false,
      created: false,
      selectedView: '1',
      buildingId: 0,
      targetTemperature: 0,
      targetTempOptions: [
        { name: '18°C', value: 18 },
        { name: '19°C', value: 19 },
        { name: '20°C', value: 20 },
        { name: '21°C', value: 21 },
        { name: '22°C', value: 22 },
        { name: '23°C', value: 23 },
        { name: '24°C', value: 24 }
      ]
    }
  },
  computed: {
    platform () {
      return this.$store.state.platform
    }
  },
  created () {
    console.log(this.building)
    this.buildingId = this.building[0].id
    this.targetTemperature = this.building[0].targetTemperature
  },
  methods: {
    async saveEditedDetails () {
      this.loading = true
      this.$store.dispatch('landlordSaveBuildingSpecificSettings', {
        buildingId: this.buildingId,
        targetTemperature: this.targetTemperature
      }).then(() => {
        this.dismissModal(this.buildingId)
      })
    },
    dismissModal (buildingId) {
      this.refreshData(buildingId)
      this.close()
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/theme/_colors';
@import "~@/theme/_backgrounds";
</style>