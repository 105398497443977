<template>
  <div class="background--modal-landlord" />
  <ion-header class="ion-no-border">
    <ion-toolbar color="transparent">
      <div slot="start">
        <ion-button
          v-if="selectedView === '1' && '2'"
          fill="clear"
          color="transparent"
          size="large"
          class="close-modal-button"
          @click="dismissModal(buildingId)"
        >
          <img
            src="../../assets/images/arrow-go-back-line.svg"
          >
        </ion-button>
        <ion-button
          v-else
          fill="clear"
          color="transparent"
          size="large"
          class="close-modal-button"
          @click="selectedView = '1'"
        >
          <img
            src="../../assets/images/arrow-go-back-line.svg"
          >
        </ion-button>
      </div>
      <div>
        <ion-title
          class="text--white text-18"
        >
          {{ building.buildingName }}
        </ion-title>
      </div>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <div v-if="!created">
      <div class="wrapper">
        <form
          class="form--dark"
          :class="platform.includes('ios') ? 'form--dark-ios' : 'form--dark-md'"
        >
          <ion-item lines="none">
            <ion-label
              class="label"
              position="floating"
            >
              Property Name
            </ion-label>
            <ion-input
              v-model="buildingName"
              enterkeyhint="done"
              type="text"
              required
            />
          </ion-item>
          <ion-item lines="none">
            <ion-label
              class="label"
              position="floating"
            >
              Property Address
            </ion-label>
            <ion-input
              v-model="buildingAddress"
              type="text"
              enterkeyhint="done"
              required
            />
          </ion-item>
          <ion-row
            class="ion-no-padding"
            style="margin-top: -15px;"
          >
            <ion-col
              class="ion-no-padding"
              style="margin-right: 5px;"
            >
              <ion-item lines="none">
                <ion-label
                  class="label"
                  position="floating"
                >
                  City
                </ion-label>
                <ion-input
                  v-model="buildingCity"
                  type="text"
                  required
                  enterkeyhint="done"
                />
              </ion-item>
            </ion-col>
            <ion-col
              class="ion-no-padding"
              style="margin-left: 5px;"
            >
              <ion-item lines="none">
                <ion-label
                  class="label"
                  position="floating"
                >
                  Post Code
                </ion-label>
                <ion-input
                  v-model="buildingPostCode"
                  type="text"
                  enterkeyhint="done"
                  required
                />
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row
            class="ion-no-padding"
          >
            <ion-col
              class="ion-no-padding"
              style="margin-right: 5px;"
            >
              <ion-item lines="none">
                <ion-label
                  class="label"
                  position="floating"
                >
                  Property Floors
                </ion-label>
                <ion-input
                  v-model="buildingFloors"
                  type="number"
                  enterkeyhint="done"
                  required
                />
              </ion-item>
            </ion-col>
            <ion-col
              class="ion-no-padding"
              style="margin-left: 5px;"
            >
              <ion-item lines="none">
                <ion-label
                  class="label"
                  position="floating"
                >
                  Flats per floor
                </ion-label>
                <ion-input
                  v-model="buildingFlatsPerFloor"
                  type="number"
                  enterkeyhint="done"
                  required
                />
              </ion-item>
            </ion-col>
          </ion-row>
        </form>
        {{ errorMessage }}
        <ion-button
          class="button-tenant mt-30"
          :disabled="loading"
          @click="saveEditedDetails"
        >
          <ion-spinner
            v-if="loading"
            name="crescent"
          />
          <span v-else>Save building details</span>
        </ion-button>
      </div>
    </div>
    <div
      v-else
      style="margin-top: 20vh;"
    >
      <span class="text-64 text--white"><i
        class="mdi mdi-check-circle"
        style="color: lightgreen;"
      /> Tenant Invited</span>
    </div>
  </ion-content>
</template>

<script>
import { defineComponent } from 'vue'
import { } from '@ionic/vue'

export default defineComponent({
  name: 'Popover',
  props: {
    refreshData: { type: Function, default: null },
    close: { type: Function, default: null },
    building: { type: Object, default: () => {} }
  },
  data () {
    return {
      loading: false,
      created: false,
      selectedView: '1',
      buildingId: 0,
      buildingAddress: '',
      buildingCity: '',
      buildingFlatsPerFloor: 0,
      buildingFloors: 0,
      buildingName: '',
      buildingPostCode: '',
      errorMessage: ''
    }
  },
  computed: {
    platform () {
      return this.$store.state.platform
    }
  },
  created () {
    console.log(this.building)
    this.buildingId = this.building[0].id
    this.buildingAddress = this.building[0].buildingAddress
    this.buildingCity = this.building[0].buildingCity
    this.buildingFlatsPerFloor = this.building[0].buildingFlatsPerFloor
    this.buildingFloors = this.building[0].buildingFloors
    this.buildingName = this.building[0].buildingName
    this.buildingPostCode = this.building[0].buildingPostCode
  },
  methods: {
    async saveEditedDetails () {
      this.loading = true
      this.$store.dispatch('landlordSaveEditedBuilding', {
        buildingId: this.buildingId,
        buildingAddress: this.buildingAddress,
        buildingCity: this.buildingCity,
        buildingFlatsPerFloor: this.buildingFlatsPerFloor,
        buildingFloors: this.buildingFloors,
        buildingName: this.buildingName,
        buildingPostCode: this.buildingPostCode
      }).then(() => {
        this.dismissModal(this.buildingId)
      })
    },
    dismissModal (buildingId) {
      this.refreshData(buildingId)
      this.close()
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/theme/_colors';
@import "~@/theme/_backgrounds";
ion-button {
  min-height: 50px;
}
</style>